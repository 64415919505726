import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage18() {
  const question = {
    title: 'Frage 18 von 35',
    headline: '',
    subline: 'Wie wichtig sind für dich Rotationsmöglichkeiten?',
  }

  const answers = [
    {
      name: 'Rotationsmöglichkeiten',
      id: 'wichtig',
      value: 'Sehr wichtig, mache ich jederzeit gern und am liebsten überall.',
      desc: '',
    },
    {
      name: 'Rotationsmöglichkeiten',
      id: 'gut',
      value: 'Finde ich gut; für einen Anteil meiner Arbeitszeit.',
      desc: '',
    },
    {
      name: 'Rotationsmöglichkeiten',
      id: 'nicht-wichtig',
      value: 'Ist für mich nicht wichtig.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={18}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-19`}
          prev={`${AREA_CARE_PATH}/spielen/frage-17`}
          index={18}
        />

        <Icon position="3" name="circle" />
      </main>
    </>
  )
}
